import React from 'react';
import './ProductPage.css';

const products = [
  {
    id: 1,
    name: 'Çiçek Mum',
    description: 'Esnek, Bükülebilir, Kırılmaz ve uzun süre yanabilen el ile şekillendirilebilen Çiçek Yapımına Uygun Çiçek Mum',
    image: '/imgs/cicek-mum-1.jpg',
    link: 'https://www.shopier.com/ShowProductNew/storefront.php?shop=cicekmumstore&sid=Y2xGVG40V0FXbncwSU9XMzBfLTFfJUMzJTg3aSVDMyVBN2VrJTIwTXVtXyA=#%C3%87i%C3%A7ek%20Mum',
    available: true,
  },
  {
    id: 2,
    name: 'Çiçek Mum Boyası',
    description: '10 Gramı ile 1 Kilogram mumu renklendirebileceğiniz etkinliği olan Toz Mum Boyası',
    image: '/imgs/cicek-mum-boya.jpg',
    link: 'https://www.shopier.com/ShowProductNew/storefront.php?shop=cicekmumstore&sid=Zkg2V1BLekw4SkdUd28yTTBfLTFfJUMzJTg3aSVDMyVBN2VrJTIwTXVtJTIwQm95YXMlQzQlQjFfIA==#%C3%87i%C3%A7ek%20Mum%20Boyas%C4%B1',
    available: true,
  },
  {
    id: 3,
    name: 'Top Ütü',
    description: 'Çiçek Mum ile çalışırken şekillendirme ve düzeltme için kullanabileceğiniz Top Ütü Seti',
    image: '/imgs/cicek-mum-top-utu.webp',
    link: 'https://www.shopier.com/ShowProductNew/products.php?id=29466152',
    available: true,
  },
  {
    id: 4,
    name: 'Çiçek Mum Döküm Matı',
    description: 'Çiçek Mum dökümünde kullanabileceğiniz Döküm Matı',
    image: '/imgs/cicek-mum-dokum-mati.jpg',
    link: 'https://www.shopier.com/ShowProductNew/products.php?id=29466406',
    available: true,
  }
];

const ProductPage = () => {
  return (
    <div className="product-page">
      {products.map((product) => (
        <div key={product.id} className="product-card">
          <img src={product.image} alt={product.name} className="product-image" />
          <h2 className="product-name">{product.name}</h2>
          <p className="product-description">{product.description}</p>
          <a
            href={product.available ? product.link : "#"}
            target={product.available ? "_blank" : "_self"}
            rel={product.available ? "noopener noreferrer" : undefined}
            className={`buy-button ${product.available ? "" : "disabled"}`}
            disabled={!product.available}
          >
            {product.available ? "Satın Al" : "Çok Yakında"}
          </a>
        </div>
      ))}
    </div>
  );
};

export default ProductPage;
