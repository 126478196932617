import React from 'react';
import './MasterclassPage.css';

const MasterclassPage = () => {
  const videos = [
    { id: 1, title: "Teknik Bilgiler ve Şakayık Modeli", src: "https://www.youtube.com/embed/j6pS5Two1fY" },
    { id: 2, title: "Gül Modeli", src: "https://www.youtube.com/embed/HlhO7Xjkb3k" },
    { id: 3, title: "Gala Çiçeği Modeli", src: "https://www.youtube.com/embed/SbYmcporm9Y" },
  ];

  return (
    <div className="masterclass-container">
      <h1>Masterclass Eğitim Videoları</h1>
      <div className="video-grid">
        {videos.map(video => (
          <div key={video.id} className="video-item">
            <h2>{video.title}</h2>
            <div className="video-wrapper">
              <iframe
                width="100%"
                height="315"
                src={video.src}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MasterclassPage;
