import { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");

  const getStudentNameByCode = (inputCode) => {
    for (const [key, value] of Object.entries(process.env)) {
      if (key.includes("STUDENT")) {
        const [studentCode, studentName] = value.split(",");
        if (studentCode === inputCode) {
          return studentName;
        }
      }
    }
    return null;
  };
  

  const login = async (code) => {
    const studentName = getStudentNameByCode(code);
  
    if (studentName) {
      setName(studentName);
      setIsAuthenticated(true);
      setErrorMessage("");
    } else {
      throw new Error("Girdiğiniz kod hatalı!");
    }
  };
  

  const logout = () => {
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        login,
        logout,
        errorMessage,
        name,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
